import { ConvertEmailRequest, WixFormBuilderWeb } from '@wix/ambassador-wix-form-builder-web/http';
import { AddFormIdMappingRequest, FormSummary } from '@wix/ambassador-wix-form-builder-web/types';
import _ from 'lodash'
import { FORMS_SERVER } from './constants/external-endpoints';

export class FormsServiceAmbassadorClient {
    private callerId = { 'X-Wix-Client-Artifact-Id': 'wix-form-builder' };

    constructor(private boundEditorSDK: BoundEditorSDK) {
    }

    private async _getAppInstance() {
        return this.boundEditorSDK.info.getAppInstance()
    }

    private _getBaseServicesClient = () => {
        return WixFormBuilderWeb(FORMS_SERVER.BASE_URL, { ignoredProtoHttpUrlPart: '/api' });
    }
    private _getRequestHeader = async () => {
        return { Authorization: await this._getAppInstance(), ...this.callerId }
    }

    public getEmailsByEmailIds = async (emailIds: string[]) => {
        return this._getBaseServicesClient().EmailService()(await this._getRequestHeader()).getEmails({
            emailIds: _.compact(emailIds)
        });
    }

    public getSiteUsers = async () => {
        return this._getBaseServicesClient().EmailService()(await this._getRequestHeader()).listSiteUsersEmail({});
    }

    public addEmail = async (email: string) => {
        return this._getBaseServicesClient().EmailService()(await this._getRequestHeader()).addEmail({ email });
    }

    public publishSite = async (data: any) => {
        return this._getBaseServicesClient().FormBuilderService()(await this._getRequestHeader()).publishSite(data)
    }

    public addContactFormMapping = async (data: AddFormIdMappingRequest) => {
        return this._getBaseServicesClient().ContactFormService()(await this._getRequestHeader()).addFormIdMapping(data)
    }

    public editDraft = async (form: FormSummary) => {
        return this._getBaseServicesClient().FormBuilderService()(await this._getRequestHeader()).editDraft({ form })
    }

    public getRestrictions = async () => {
        return this._getBaseServicesClient().PremiumService()(await this._getRequestHeader()).restrictions({});
    }

    public convertContactFormEmails = async (convertContactFormEmailsRequest: ConvertEmailRequest) => {
        return this._getBaseServicesClient().ContactFormService()(await this._getRequestHeader()).convertEmail(convertContactFormEmailsRequest);
    }
}