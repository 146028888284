import _ from 'lodash'
import { ResponsiveLayout, StackItemLayout } from '@wix/platform-editor-sdk'
import { FIELDS_ROLES_TO_APPEAR_BEFORE_USER_NEW_FIELD } from '../../../constants/roles'

const metaData = {
  isPreset: false,
  schemaVersion: '1.0',
  isHidden: false,
}

const componentsLayout = (height): ResponsiveLayout['componentLayouts'] => [
  {
    type: 'ComponentLayout',
    width: {
      type: 'percentage',
      value: 90,
    },
    height: height
      ? {
          type: 'px',
          value: height,
        }
      : { type: 'auto' },
    hidden: false,
    breakpoint: undefined,
  },
]

const createGridItemLayout = ({
  gridArea,
  height,
}: {
  gridArea?: ResponsiveLayoutData['gridArea']
  height?: number
  incrementPosition?: boolean
}): ResponsiveLayout => {
  return {
    id: '',
    type: 'LayoutData',
    metaData,
    containerLayouts: [],
    componentLayouts: componentsLayout(height),
    itemLayouts: [
      {
        type: 'GridItemLayout',
        alignSelf: 'center',
        justifySelf: 'center',
        gridArea: gridArea || {
          rowStart: 1,
          rowEnd: 2,
          columnStart: 1,
          columnEnd: 2,
        },
        breakpoint: undefined,
      },
    ],
  }
}

const createStackItemLayout = ({
  order,
  height,
}: {
  order?: number
  height?: number
}): ResponsiveLayout => ({
  id: '',
  type: 'LayoutData',
  metaData,
  containerLayouts: [],
  componentLayouts: componentsLayout(height),
  itemLayouts: [
    {
      type: 'StackItemLayout',
      alignSelf: 'center',
      justifySelf: 'center',
      order: order || 1,
      margins: {
        left: {
          type: 'px',
          value: 20,
        },
        top: {
          type: 'px',
          value: 20,
        },
      },
      breakpoint: undefined,
    },
  ],
})

const createItemLayout = ({
  itemLayoutType,
  height,
  layoutData,
}: {
  itemLayoutType: ResponsiveItemLayoutType
  height?: number
  layoutData?: ResponsiveLayoutData
}): ResponsiveLayout => {
  const { order, gridArea } = layoutData || {}

  switch (itemLayoutType) {
    case 'GridItemLayout':
      return createGridItemLayout({ height, gridArea })
    default:
      return createStackItemLayout({ height, order })
  }
}

const fieldLayouts = (fieldsData: FormField[]): AnyItemLayout[] =>
  fieldsData
    .filter((fieldData) => _.includes(FIELDS_ROLES_TO_APPEAR_BEFORE_USER_NEW_FIELD, fieldData.role))
    .map((fieldData) => fieldData.layoutResponsive.itemLayouts[0])

const findNewFieldStackOrder = (fieldsData: FormField[]): ResponsiveLayoutData['order'] => {
  const fieldsLayout = fieldLayouts(fieldsData) as StackItemLayout[]

  const lastFieldLayout: StackItemLayout = _.maxBy(fieldsLayout, 'order')

  return _.get(lastFieldLayout, 'order', 0) + 1
}

const findNewFieldGridArea = (fieldsData: FormField[]): ResponsiveLayoutData['gridArea'] => {
  const fieldsLayout = fieldLayouts(fieldsData) as GridItemLayout[]
  const lastFieldLayout: GridItemLayout = _.maxBy(fieldsLayout, 'gridArea.rowEnd')

  return {
    rowStart: lastFieldLayout.gridArea.rowStart,
    rowEnd: lastFieldLayout.gridArea.rowEnd,
    columnStart: 1,
    columnEnd: 1,
  }
}

const findResponsiveLayoutData = (
  itemLayoutType: ResponsiveItemLayoutType,
  fieldsData: FormField[],
): ResponsiveLayoutData => {
  switch (itemLayoutType) {
    case 'GridItemLayout':
      return { gridArea: findNewFieldGridArea(fieldsData) }
    default:
      return { order: findNewFieldStackOrder(fieldsData) }
  }
}

const calcUpdatesForStackFieldsByNewOrder = (
  responsiveLayouts: {
    componentRef: ComponentRef
    layoutResponsive: ResponsiveLayout
    role?: string
  }[],
  newFieldOrder: number,
): { componentRef: ComponentRef; responsiveLayout: ResponsiveLayout }[] => {
  const fieldsNeededForUpdate = responsiveLayouts.filter(
    (fieldData) => getOrderFromResponsiveLayout(fieldData.layoutResponsive) >= newFieldOrder,
  )

  return fieldsNeededForUpdate.map((fieldData) => {
    const defaultStackItemLayout = fieldData.layoutResponsive.itemLayouts[0] as StackItemLayout
    return {
      componentRef: fieldData.componentRef,
      responsiveLayout: {
        ...fieldData.layoutResponsive,
        itemLayouts: [{ ...defaultStackItemLayout, order: defaultStackItemLayout.order + 1 }],
      },
    }
  })
}

const getOrderFromResponsiveLayout = (layoutResponsive: ResponsiveLayout): number =>
  (layoutResponsive.itemLayouts[0] as StackItemLayout).order

const getItemLayoutData = (
  layoutResponsive: ResponsiveLayout,
  incrementPosition?: boolean,
): ResponsiveLayoutData => {
  const itemLayout = _.get(layoutResponsive, 'itemLayouts[0]')

  if (!itemLayout) {
    return undefined
  }

  switch (itemLayout.type) {
    case 'GridItemLayout':
      return { gridArea: itemLayout.gridArea }
    default:
      const order = (itemLayout as StackItemLayout).order
      return { order: incrementPosition ? order + 1 : order }
  }
}

const getContainerType = (layoutResponsive) => _.get(layoutResponsive, 'containerLayouts[0].type')

const getItemLayoutType = (containerLayout): ResponsiveItemLayoutType => {
  const containerLayoutType: ResponsiveContainerLayoutType = getContainerType(
    _.get(containerLayout, '[0].layoutResponsive'),
  )
  switch (containerLayoutType) {
    case 'GridContainerLayout':
      return 'GridItemLayout'
    default:
      return 'StackItemLayout'
  }
}

export {
  createItemLayout,
  findResponsiveLayoutData,
  getItemLayoutData,
  calcUpdatesForStackFieldsByNewOrder,
  getContainerType,
  getItemLayoutType,
}
